import React, { useEffect } from 'react';
import './Donate.css';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Donate = () => {
  useEffect(() => {
    // Initialize AOS library
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: false,     // Trigger animations every time the section comes into view
    });
  }, []);

  return (
    <>
      <div className="donate-container">
        <section className="donate-header" data-aos="fade-up">
          <h1>Donate to Our Cause</h1>
          <p>Your generous donation will help us make a positive impact on underserved communities.</p>
        </section>

        <section className="donate-info" data-aos="fade-up">
          <h2>Bank Details for Donation</h2>
          <p><strong>Bank Name:</strong> State Bank of India</p>
          <p><strong>Account Name:</strong> DEVELOPMENT EDUCATION & WELFARE TRUST</p>
          <p><strong>Account Number:</strong> 34748238737</p>
          <p><strong>IFSC Code:</strong> SBIN0001447</p>
        </section>

        <section className="donate-note" data-aos="fade-up">
          <h2>How to Donate</h2>
          <p>To make a donation, you can transfer funds directly to the above account details via bank transfer. Please use your name or organization name as the reference so that we can acknowledge your contribution.</p>
        </section>
      </div>

      {/* Footer - Outside the content container */}
      <div className="footer-container">
        <Footer />
      </div>
    </>
  );
};

export default Donate;
