import React, { useEffect } from "react";
import slide4 from "../assets/slide4.png";
import slide2 from "../assets/slide2.png";
import slide7 from "../assets/slide7.png";
import "./AboutUs.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutUs = () => {
  // Initialize AOS on component mount
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration (in ms)
      once: false,     // Trigger the animation every time the section comes into view
    });
  }, []);

  return (
    <div className="about-us">
      {/* About Us Section */}
      <section className="about-section" data-aos="fade-up">
        <h2>About Us</h2>
        <p>
          The founder Mr. Lucas Thapa, Chairman of Development Education and Welfare Trust, was established in the year of 2000, and registered this organization as a subordinate NGO (Trust) of West Bengal, registration number is IV/93/2000. The main objective and its philosophy is social, cultural, economic, and educational to bring holistic development and change in the society through education (Holistic Transformation). Many have been inspired by this social work. From the very beginning, he has started a campaign of change in the society by working on some social issues. And this is happening continuously, and I am trying to present these issues or points before you.
        </p>
      </section>

      {/* Featured Activities Section */}
      <section className="featured-activities-section" data-aos="fade-up">
        <h2>Featured Activities</h2>
        <div className="cards">
          <div className="card" data-aos="fade-up">
            <img src={slide4} alt="Activity 1" />
            <h3>Relief Distribution</h3>
            <p>Providing relief materials to flood and earthquake-affected people.</p>
          </div>
          <div className="card" data-aos="fade-up">
            <img src={slide2} alt="Activity 2" />
            <h3>Skill Development</h3>
            <p>Training youth through skill development programs for self-reliance.</p>
          </div>
          <div className="card" data-aos="fade-up">
            <img src={slide7} alt="Activity 3" />
            <h3>Women Empowerment</h3>
            <p>Organizing seminars and training programs to empower women and children.</p>
          </div>
        </div>
      </section>

      {/* Our Activities Section */}
      <section className="our-activities-section" data-aos="fade-up">
        <h2>Our Activities</h2>
        <div className="activities-grid">
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-users-cog activity-icon"></i>
            <h3>Making Community Leaders Self-Reliant</h3>
            <p>Empowering community leaders with skills and resources for sustainable development.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-chalkboard-teacher activity-icon"></i>
            <h3>Awareness Training on Social Issues</h3>
            <p>Conducting training programs to spread awareness on critical social issues.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-capsules activity-icon"></i>
            <h3>Awareness on Drug Eradication</h3>
            <p>Initiatives to educate and eliminate drug abuse in vulnerable communities.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-broom activity-icon"></i>
            <h3>Awareness Program on Cleanliness</h3>
            <p>Promoting hygiene and sanitation to improve health and well-being.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-book-reader activity-icon"></i>
            <h3>Making Women and Children Literate</h3>
            <p>Providing education to underprivileged women and children for a better future.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-hands-helping activity-icon"></i>
            <h3>Relief for Disaster-Affected People</h3>
            <p>Distributing essential supplies to those affected by natural disasters.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-child activity-icon"></i>
            <h3>Assistance to Orphans and Widows</h3>
            <p>Providing emotional and financial support to orphans and widows.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-tools activity-icon"></i>
            <h3>Skill Development Training for Youth</h3>
            <p>Organizing camps to teach youth essential skills for employment and self-reliance.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-female activity-icon"></i>
            <h3>Women Empowerment Seminars</h3>
            <p>Seminars and programs to inspire and uplift women in society.</p>
          </div>
          <div className="activity-card" data-aos="fade-up">
            <i className="fas fa-ban activity-icon"></i>
            <h3>Ending Dowry System and Child Marriage</h3>
            <p>Spreading awareness and advocating against dowry practices and child marriage.</p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us-section" data-aos="fade-up">
        <h2>Why Choose Us</h2>
        <div className="questions">
          <div className="question" data-aos="fade-up">
            <h3>What makes us unique?</h3>
            <p>Our holistic approach integrates education and skill-building to address core societal issues.</p>
          </div>
          <div className="question" data-aos="fade-up">
            <h3>How do we ensure transparency?</h3>
            <p>We maintain open communication with donors and stakeholders, providing regular updates.</p>
          </div>
          <div className="question" data-aos="fade-up">
            <h3>What is our impact?</h3>
            <p>We’ve positively impacted thousands of lives through education, disaster relief, and empowerment programs.</p>
          </div>
        </div>
      </section>

      {/* How Can You Help Section */}
      <section className="how-can-you-help-section" data-aos="fade-up">
        <h2>How Can You Help?</h2>
        <div className="help-cards">
          <div className="help-card" data-aos="fade-up">
            <i className="fas fa-donate"></i>
            <h3>Donate</h3>
            <p>Support our initiatives by donating to make a meaningful impact.</p>
          </div>
          <div className="help-card" data-aos="fade-up">
            <i className="fas fa-hands-helping"></i>
            <h3>Volunteer</h3>
            <p>Offer your time and skills to help us achieve our mission.</p>
          </div>
          <div className="help-card" data-aos="fade-up">
            <i className="fas fa-bullhorn"></i>
            <h3>Spread Awareness</h3>
            <p>Share our programs and campaigns to reach a larger audience.</p>
          </div>
          <div className="help-card" data-aos="fade-up">
            <i className="fas fa-handshake"></i>
            <h3>Partner with Us</h3>
            <p>Collaborate on projects that uplift communities.</p>
          </div>
          <div className="help-card" data-aos="fade-up">
            <i className="fas fa-coins"></i>
            <h3>Fundraise</h3>
            <p>Participate in our campaigns to generate support and resources.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
