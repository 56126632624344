import React, { useEffect } from "react";
import "./ContactUs.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  useEffect(() => {
    // Initialize AOS library
    AOS.init({
      duration: 1000,  // Animation duration
      once: false,     // Trigger animations every time the section comes into view
    });
  }, []);

  return (
    <section className="contact-us">
      <h2 data-aos="fade-up">Contact Us</h2>
      <p data-aos="fade-up">We would love to hear from you. Please send us a message and we'll get back to you as soon as possible.</p>

      <div className="contact-form" data-aos="fade-up">
        <form>
          <div className="form-group" data-aos="fade-up">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>

          <div className="form-group" data-aos="fade-up">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>

          <div className="form-group" data-aos="fade-up">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>
          </div>

          <button type="submit" className="submit-btn" data-aos="fade-up">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
