import React, { useState } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Importing the logo image
import logo from '../assets/logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to handle the opening and closing of mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
        {/* Using Link to make the logo clickable and redirect to Home */}
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      {/* Desktop Menu */}
      <ul className="nav-items">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/Contact">Contact</Link></li>
        
      </ul>

      {/* Contact Button moved to right only visible on Desktop */}
      <Link to="/Donate">
        <button className="contact-btn desktop-only">Donate</button>
      </Link>

      {/* Hamburger Menu for Mobile */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="close-btn" onClick={toggleMobileMenu}>X</div>
        <ul>
          <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMobileMenu}>About</Link></li>
          <li><Link to="/contact" onClick={toggleMobileMenu}>Contact</Link></li>
          {/* <li><Link to="/admission" onClick={toggleMobileMenu}>Admission</Link></li> */}
          <li>
            {/* Show "Contact" as a button in the mobile menu */}
            <Link to="/donate">
              <button className="contact-btn" onClick={toggleMobileMenu}>Donate</button>
            </Link>
          </li>
        </ul>

        {/* Footer Text */}
        <div className="mobile-menu-footer">
          Bangalore Bible Institute & College
        </div>
      </div>

      {/* Overlay when mobile menu is open */}
      {isMobileMenuOpen && <div className="overlay" onClick={toggleMobileMenu}></div>}
    </nav>
  );
};

export default Navbar;
