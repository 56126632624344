import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

// Import images from src/assets
import slide1 from "../assets/slide1.png";
import slide9 from "../assets/slide9.png";
import slide8 from "../assets/slide8.png";

const images = [slide1, slide9, slide8];

const Hero = () => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Change images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleDonateClick = () => {
    navigate("/donate");
  };

  return (
    <div className="hero">
      {/* Slider */}
      <div className="hero-slider">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`hero-slide ${
              index === currentImageIndex ? "active" : ""
            }`}
          />
        ))}
      </div>

      {/* Overlay Content */}
      <div className="hero-overlay">
        <div className="hero-content">
          <h1 className="hero-title">
            Empowering Change with Development Education & Welfare Trust
          </h1>
          <p className="hero-subtitle">
            Join us in making a lasting impact on lives and building a brighter future together.
          </p>
          <button className="hero-cta" onClick={handleDonateClick}>
            Donate Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
