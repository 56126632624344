import React from "react";
import Hero from "../components/Hero.jsx";
import AboutUs from "../components/AboutUs.jsx";
import ContactUs from "../components/ContsctUs.jsx";
import Footer from "../components/Footer.jsx";

const Home = () => {
    return (
        <div>
            <Hero />
            <AboutUs />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default Home;
