import React, { useEffect } from "react";
import "./About.css";
import ContactUs from '../components/ContsctUs';  // Fixed typo in import path (ContactUs)
import Footer from '../components/Footer';
import Gallery from "../components/Gallery";


// Import the different images
import founderImage from "../assets/founder.png";  // Founder image for the About section
import teamMemberImage from "../assets/founder.png";  // Team member image for the Team section
import AOS from 'aos';
import 'aos/dist/aos.css';  // AOS CSS

const About = () => {
  useEffect(() => {
    // Initialize AOS library
    AOS.init({
      duration: 1000,  // Animation duration
      once: false,     // Trigger animations every time the section comes into view
    });
  }, []);

  return (
    <>
      <div className="about-container">
        {/* About Us Section */}
        <section className="about-hero" data-aos="fade-up">
          <h1 className="about-title">About Us</h1>
          <p className="about-subtitle">
            Empowering communities and transforming lives through education and welfare.
          </p>
        </section>

        {/* About Us - Founder Section */}
        <section className="about-founder" data-aos="fade-up">
          <div className="founder">
            <img src={founderImage} alt="Founder" className="founder-image" />
            <div className="founder-details">
              <h2>About the Founder</h2>
              <p>
              The founder Mr. Lucas Thapa, Chairman of Development Education and Welfare Trust, was established in the year of 2000, and registered this organization as a subordinate NGO (Trust) of West Bengal, registration number is IV/93/2000. The main objective and its philosophy is social, cultural, economic, and educational to bring holistic development and change in the society through education (Holistic Transformation).
              </p>
            </div>
          </div>
        </section>

        {/* Our Mission Section */}
        <section className="about-mission" data-aos="fade-up">
          <h2 className="section-title">Our Mission</h2>
          <ul className="mission-list">
            <li>Making community leaders self-reliant</li>
            <li>Awareness training program on social issues</li>
            <li>Awareness program on drug eradication</li>
            <li>Awareness program towards cleanliness </li>
            <li>Making women and children literate</li>
            <li>Distribution of relief material among flood and earthquake affected people </li>
            <li>Providing assistance to orphans and widows </li>
            <li>Organization of skill development training camp for youth</li>
            <li>Seminar or awareness seminar on women empowerment</li>
            <li>The organization's decision to stop or end the dowry system and child marriage</li>
          </ul>
        </section>

        {/* Our Vision Section */}
        <section className="about-vision" data-aos="fade-up">
          <h2 className="section-title">Our Vision</h2>
          <div className="vision-cards">
            <div className="vision-card">
              <h3>Self-reliance of Community Leaders</h3>
              <p>Empowering leaders within the community to become self-sufficient, enabling them to lead initiatives and create lasting change.</p>
            </div>
            <div className="vision-card">
              <h3>Social Awareness and Training</h3>
              <p> Conducting awareness programs to address critical social issues, such as drug eradication, cleanliness, and women's rights.</p>
            </div>
            <div className="vision-card">
              <h3>Education and Relief Programs</h3>
              <p>Providing educational opportunities, especially for women and children, and distributing relief materials to those affected by natural disasters like floods and earthquakes.</p>
            </div>
          </div>
        </section>

        {/* Meet Our Team Section */}
        <section className="about-team" data-aos="fade-up">
          <h2 className="section-title">Meet Our Team</h2>
          <div className="team-cards">
            <div className="team-card">
              <img src={teamMemberImage} alt="Team Member" className="team-photo" />
              <h3 className="team-name">Lucas Thapa</h3>
              <p className="team-role">Founder</p>
            </div>
            {/* Add more team members as needed */}
          </div>
        </section>


        {/* Gallery */}
        <Gallery />



        {/* Contact Us Section */}
        <ContactUs data-aos="fade-up" />
      </div>

      {/* Footer - Outside the content container */}
      <div className="footer-container" data-aos="fade-up">
        <Footer />
      </div>
    </>
  );
};

export default About;
