import React, { useState, useEffect } from 'react';
import './Contact.css';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process form submission here (e.g., send data to server)
    alert('Form submitted successfully!');
  };

  useEffect(() => {
    // Initialize AOS library
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: false,     // Trigger animations every time the section comes into view
    });
  }, []);

  return (
    <>
      <div className="contact-container">
        {/* Contact Header Section */}
        <section className="contact-header" data-aos="fade-up">
          <h1>Contact Us</h1>
          <p>If you have any questions, feel free to reach out to us!</p>
        </section>

        {/* Contact Form Section */}
        <section className="contact-form" data-aos="fade-up">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-field" data-aos="fade-up">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-field" data-aos="fade-up">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-field" data-aos="fade-up">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="submit-btn" data-aos="fade-up">
              Send Message
            </button>
          </form>
        </section>

        {/* Contact Information Section */}
        <section className="contact-info" data-aos="fade-up">
          <h2>Our Contact Information</h2>
          <p><strong>Address:</strong> Chuapara, Kalchini - West Bengal, India</p>
          <p><strong>Phone:</strong> +91 9932144384</p>
          <p><strong>Email:</strong> lukasdewt@yahoo.com</p>
        </section>

        {/* Contact Map Section */}
        <section className="contact-map" data-aos="fade-up">
          <h2>Our Location</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d70182.29928694616!2d89.43522379778864!3d26.68639212899315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1shttps%3A%2F%2Fmaps.app.goo.gl%2FDGEUZKY3BBgqyagh9!5e1!3m2!1sen!2sin!4v1732552679294!5m2!1sen!2sin"
            
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            title="NGO Location"
          ></iframe>
        </section>
      </div>

      {/* Footer - Outside the content container */}
      <div className="footer-container">
        <Footer />
      </div>
    </>
  );
};

export default Contact;
