import React, { useState } from "react";
import "./Gallery.css";

const Gallery = () => {
  const images = [
    require("../assets/slide1.png"),
    require("../assets/slide2.png"),
    require("../assets/slide3.png"),
    require("../assets/slide4.png"),
    require("../assets/slide5.png"),
    require("../assets/slide6.png"),
    require("../assets/slide7.png"),
    require("../assets/slide8.png"),
    require("../assets/slide9.png"),
    require("../assets/slide10.png"),
  ];

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const goToNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Gallery</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Gallery Image ${index + 1}`}
            className="gallery-image"
            onClick={() => openLightbox(index)}
          />
        ))}
      </div>

      {lightboxOpen && (
        <div className="lightbox">
          <div className="lightbox-overlay" onClick={closeLightbox}></div>
          <div className="lightbox-content">
            <button className="lightbox-close" onClick={closeLightbox}>
              &times;
            </button>
            <button className="lightbox-prev" onClick={goToPrev}>
              &#10094;
            </button>
            <img
              src={images[currentIndex]}
              alt={`Lightbox Image ${currentIndex + 1}`}
              className="lightbox-image"
            />
            <button className="lightbox-next" onClick={goToNext}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
