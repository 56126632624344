import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left">
          <h2>Development Education & Welfare Trust</h2>
          <p className="tagline">Empowering Communities, Transforming Lives</p>
          <div className="social-icons">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram social-icon"></i>
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook social-icon"></i>
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube social-icon"></i>
            </a>
          </div>
        </div>
        <div className="right">
          <p>Email: lukasdewt@yahoo.com</p>
          <p>Phone: +91 9932144384</p>
          <p>Address: Chinchua Tea Estate, Chinchula Road, Kalchini, 735217, West Bengal, India</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          © 2024 Development Education & Welfare Trust. All Rights Reserved |{" "}
          <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer">
            tirkey.in
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
